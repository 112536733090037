import React from "react";

import dayjs from "@calcom/dayjs";
import { Button, Dialog, DialogContent } from "@calcom/ui";

import { formatNumber } from "@lib/practice/helper";

import { PaymentSuccessIcon } from "@components/Icons";

type Props = {
  title: string;
  description: string;
  amount: number;
  account: string;
  date: string;
  source: string;
  email: string;
  phone: string;
  open: boolean;
  handler: React.Dispatch<React.SetStateAction<boolean>>;
  footer?: React.ReactNode;
  onClose: () => void;
  applySurcharge?: boolean;
  surchargeApplied?: boolean;
};

export default function PaymentSuccessDialog({
  title,
  description,
  amount,
  account,
  date,
  source,
  email,
  phone,
  open,
  handler,
  onClose,
  footer = false,
  applySurcharge,
  surchargeApplied,
}: Props) {
  return (
    <Dialog open={open} onOpenChange={handler}>
      <DialogContent className="min-w-screen flex min-h-screen items-center justify-center rounded-none bg-[#F9FAFA] p-0 py-2">
        <div className="max-w-xl space-y-6 rounded-2xl border bg-white p-8 text-gray-600">
          <div className="flex flex-col items-center justify-center gap-2">
            <PaymentSuccessIcon />
            <p className="text-2xl font-medium text-gray-900">{title}</p>
            <p>{description}</p>
          </div>
          <div className="space-y-3">
            {source !== "Payment Request" && (
              <div className="flex items-center justify-between">
                <span>Payment Source:</span>
                <span>{source}</span>
              </div>
            )}
            <div className="flex items-center justify-between">
              <span>Account:</span>
              <span>{account}</span>
            </div>
            <div className="flex items-center justify-between">
              <span>{source === "Payment Request" ? "Amount Requested:" : "Amount:"}</span>
              <span>${formatNumber(amount, "balance")}</span>
            </div>
            <div className="flex items-center justify-between">
              <span>Payment Date:</span>
              <span>{dayjs(date).format("MMM DD, YYYY")}</span>
            </div>
            {applySurcharge && surchargeApplied && (
              <div className="flex items-center justify-between font-semibold">
                <span>Surcharge Enabled, Patient Paid CC Fee</span>
              </div>
            )}
            {applySurcharge && !surchargeApplied && (
              <div className="flex items-center justify-between font-semibold">
                <span>Credit card not used, no surcharge incurred</span>
              </div>
            )}
          </div>
          <div className="border-b border-gray-200" />
          <div className="grid grid-cols-2 gap-4">
            <Button
              className="flex h-10 justify-center border bg-white text-gray-900 hover:bg-white hover:text-gray-900"
              onClick={() => {
                onClose();
              }}>
              Close Popup
            </Button>
            <Button
              className="flex h-10 justify-center bg-black text-white hover:bg-black hover:text-white"
              onClick={() => {
                handler(false);
              }}>
              {source === "Payment Request" ? "Send Another Request" : "New Payment"}
            </Button>
          </div>
          {footer}
        </div>
      </DialogContent>
    </Dialog>
  );
}
