import { ChevronDownIcon } from "@heroicons/react/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { trpc } from "@calcom/trpc/react";
import {
  Button,
  Dropdown,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
  Form,
  Label,
  Select,
  showToast,
  Switch,
  TextAreaField,
  TextField,
  Tooltip,
} from "@calcom/ui";

import type { PRTemplateOption } from "@lib/constant";
import { PRTemplateOptions } from "@lib/constant";
import { formatNumber, formatPhoneNumber } from "@lib/practice/helper";
import { getBasicRequestEmailTemplate } from "@lib/templates/paymentRequest/basicRequest";
import { getCustomRequestEmailTemplate } from "@lib/templates/paymentRequest/custom";
import { getEncouragementEmailTemplate } from "@lib/templates/paymentRequest/encouragement";
import { getFinalEmailTemplate } from "@lib/templates/paymentRequest/final";
import { getFollowUpEmailTemplate } from "@lib/templates/paymentRequest/followUp";
import { getReminderEmailTemplate } from "@lib/templates/paymentRequest/reminder";
import { getUrgentEmailTemplate } from "@lib/templates/paymentRequest/urgent";

import { DollarIcon, InformationIcon, MailIcon, MobilePhoneIcon } from "@components/Icons";
import PaymentSuccessDialog from "@components/patients/PaymentSuccessDialog";

type FormValues = {
  amount: number;
  email: string;
  phone: string;
  onEmail: boolean;
  onText: boolean;
  message: string;
  subject: string;
  discountAmount: number;
  discountPercentage: number;
};

type Props = {
  patientId?: number;
  officeId: number;
  officename?: string;
  setPaymentDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function PaymentRequest({ patientId, officeId, officename, setPaymentDialog }: Props) {
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const [patient, setPatient] = useState<number | undefined>(patientId);
  const [template, setTemplate] = useState<PRTemplateOption | undefined>(PRTemplateOptions[0]);
  const [statementLink, setStatementLink] = useState<string>("");
  const [paymentLink, setPaymentLink] = useState<string>("");
  const [emailTemplate, setEmailTemplate] = useState<string>("");
  const [emailContent, setEmailContent] = useState<{
    patientName: string | undefined;
    officeName: string | undefined;
    officeNumber: string | undefined | null;
    logo: string | undefined | null;
    officeAddress: string | undefined | null;
    senderName: string | undefined;
    senderAddress: string | undefined | null;
    headerColor: string | undefined | null;
    statementLink: string | undefined;
    balanceAmount: number;
  }>({
    patientName: "",
    officeName: "",
    officeNumber: "",
    logo: "",
    officeAddress: "",
    senderName: "",
    senderAddress: "",
    headerColor: "",
    statementLink: "",
    balanceAmount: 0,
  });

  const [paymentSuccessDialogOpen, setPaymentSuccessDialogOpen] = useState(false);

  const form = useForm<FormValues>({
    defaultValues: {
      amount: 0,
      email: "",
      phone: "",
      onEmail: true,
      onText: true,
      subject: "We're kindly requesting payment",
      discountAmount: 0,
      discountPercentage: 0,
    },
    resolver: zodResolver(
      z
        .object({
          amount: z.number().min(0.01),
          email: z.string().email().or(z.literal("")),
          phone: z.string().or(z.literal("")),
          onEmail: z.boolean(),
          onText: z.boolean(),
          message: z.string().min(1, "Required"),
          subject: z.string().min(1, "Required"),
          discountAmount: z.number().optional(),
          discountPercentage: z.number().optional(),
        })
        .superRefine((values, ctx) => {
          if (values.onEmail && !values.email) {
            ctx.addIssue({
              message: "Please enter valid email address",
              code: z.ZodIssueCode.custom,
              path: ["email"],
            });
          }
          if (values.onText && !values.phone) {
            ctx.addIssue({
              message: "Please enter valid phone number",
              code: z.ZodIssueCode.custom,
              path: ["phone"],
            });
          }
          if (!values.onText && !values.onEmail) {
            ctx.addIssue({
              message: "Either phone or email should be filled in.",
              code: z.ZodIssueCode.custom,
              path: ["email"],
            });
          }
        })
    ),
  });

  const { register, formState, control, setValue, watch, getValues, reset } = form;

  const watchShowAmount = watch("amount");
  const watchOnEmail = watch("onEmail");
  const watchOnText = watch("onText");
  const watchSubject = watch("subject");
  const watchMessage = watch("message");

  const sendRequest = trpc.viewer.patients.sendNotification.useMutation({
    onSuccess: (data) => {
      setLoading(false);
      setPaymentSuccessDialogOpen(true);
      if (data && data.email.on) {
        showToast(data.email.status ? "Email sent" : "Email failed", data.email.status ? "success" : "error");
      }
      if (data && data.sms.on) {
        showToast(data.sms.status ? "SMS sent" : "SMS failed", data.sms.status ? "success" : "error");
      }
      if (!data) {
        showToast("Error occured", "error");
      }
    },
    onError: (e) => {
      setLoading(false);
      showToast("Error occured", "error");
    },
  });

  const { data: patientOptions, isLoading } = trpc.viewer.patients.getNameList.useQuery({
    patientName: debouncedSearchQuery,
    patientId: debouncedSearchQuery ? undefined : patient,
    officeId,
  });

  const { data: singlePatient } = trpc.viewer.patients.getById.useQuery(
    {
      billingOfficeId: officeId,
      patientId: Number(patient),
    },
    { enabled: !!patient }
  );

  const { data: office } = trpc.viewer.office.getById.useQuery({
    officeId: officeId,
  });

  const handleSubmit = (data: FormValues) => {
    if (!patient) {
      showToast("Please select patient", "error");
      return;
    }
    setLoading(true);
    sendRequest.mutate({
      ...data,
      billingOfficeId: officeId,
      patientId: patient,
      emailTemplate,
    });
  };

  const handleCopyLink = () => {
    if (!watchMessage.includes(paymentLink)) {
      setValue("message", watchMessage + `\n${paymentLink}`);
      setEmailTemplate(
        getCustomRequestEmailTemplate({
          ...emailContent,
          subject: watchSubject,
          message: watchMessage + `\n${paymentLink}`,
        })
      );
    }
  };

  const handleCopyStatementLink = () => {
    if (!watchMessage.includes(statementLink)) {
      setValue("message", watchMessage + `\n${statementLink}`);
      setEmailTemplate(
        getCustomRequestEmailTemplate({
          ...emailContent,
          subject: watchSubject,
          message: watchMessage + `\n${paymentLink}`,
        })
      );
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(search);
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    setValue("amount", singlePatient?.balance || 0);
    setValue("email", singlePatient?.email || "");
    setValue("phone", formatPhoneNumber(singlePatient?.phoneNumbers[0]?.number || ""));
    setValue("onEmail", singlePatient?.email ? true : false);
    setValue("onText", singlePatient?.phoneNumbers[0]?.number ? true : false);
    setStatementLink(
      singlePatient
        ? `${process.env.NEXT_PUBLIC_WEBAPP_URL}/${officename}/statement-link?pid=${singlePatient?.id}`
        : "(Statement link will pre-fill based on account selected)"
    );
    setPaymentLink(`${process.env.NEXT_PUBLIC_WEBAPP_URL}/${officename}/payment-link`);
  }, [singlePatient, officename]);

  useEffect(() => {
    setEmailContent({
      patientName: (singlePatient?.firstName || "Patient") + " " + (singlePatient?.lastName || "Name"),
      officeName: office?.name,
      officeNumber: office?.phone,
      logo: office?.logo,
      officeAddress: office?.address1,
      senderName: office?.name,
      senderAddress: office?.email,
      headerColor: office?.headerColor,
      statementLink: singlePatient
        ? `${process.env.NEXT_PUBLIC_WEBAPP_URL}/${officename}/statement-link?pid=${singlePatient?.id}`
        : "(Statement link will pre-fill based on account selected)",
      balanceAmount: watchShowAmount,
    });
  }, [singlePatient, officename, office, watchShowAmount]);

  useEffect(() => {
    if (template) {
      switch (template.value) {
        case "basic":
          setEmailTemplate(getBasicRequestEmailTemplate(emailContent));
          setValue("subject", "Your Payment is Due—Please Review Your Bill");
          // setValue(
          //   "message",
          //   `Hi ${singlePatient?.firstName || "Patient"} ${
          //     singlePatient?.lastName || "Name"
          //   }, \n\nThis is a reminder that you have a balance of $${formatNumber(
          //     watchShowAmount,
          //     "balance"
          //   )} with ${
          //     office?.name
          //   }. Please click the link below to review your statement and make a payment: \n\n${statementLink}\n\nIf you have already made this payment or believe this message was sent in error, please disregard it.\n\nThank you,\n\n${
          //     office?.name
          //   }\n\nReply STOP to opt-out.`
          // );
          setValue(
            "message",
            `Hi ${
              emailContent.patientName
            }, \n\nThis is a reminder that you have a balance of $${formatNumber(
              emailContent.balanceAmount,
              "balance"
            )} with ${
              emailContent.officeName
            }. Please click the link below to review your statement and make a payment: \n\n${
              emailContent.statementLink
            }\n\nIf you have already made this payment or believe this message was sent in error, please disregard it.\n\nThank you,\n\n${
              emailContent.officeName
            }\n\nReply STOP to opt-out.`
          );
          break;
        case "reminder":
          setEmailTemplate(getReminderEmailTemplate(emailContent));
          setValue("subject", `Quick Reminder: Your ${office?.name} Bill is Ready`);
          setValue(
            "message",
            `Hi ${
              emailContent.patientName
            }, \n\nWe hope this message finds you well! It looks like there’s a balance of $${formatNumber(
              emailContent.balanceAmount,
              "balance"
            )} on your account with ${
              emailContent.officeName
            }. You can review your bill and make a payment by clicking the link below: \n\n${
              emailContent.statementLink
            }\n\nIf you’ve already settled this balance or received this in error, please feel free to disregard it.\n\Thank you, and have a wonderful day!\n\nReply STOP to opt-out.`
          );
          break;
        case "encourage":
          setEmailTemplate(getEncouragementEmailTemplate(emailContent));
          setValue("subject", `Let’s Get This Taken Care Of—View Your ${office?.name} Bill Today`);
          setValue(
            "message",
            `Hi ${emailContent.patientName}, \n\nWe wanted to remind you about your balance of ${formatNumber(
              emailContent.balanceAmount,
              "balance"
            )} with ${
              emailContent.officeName
            }. Taking care of it is quick and easy—just click the link below to view your bill and make a payment: \n\n${
              emailContent.statementLink
            }\n\nIf you’ve already paid or think this was sent in error, no problem—feel free to disregard this reminder.\n\Thanks for being part of the Caresuite family!\n\nReply STOP to opt-out.`
          );
          break;
        case "follow-up":
          setEmailTemplate(getFollowUpEmailTemplate(emailContent));
          setValue("subject", `Payment Reminder: View Your ${office?.name} Statement`);
          setValue(
            "message",
            `Hi ${
              emailContent.patientName
            }, \n\nThis is a courtesy reminder regarding your outstanding balance of $${formatNumber(
              emailContent.balanceAmount,
              "balance"
            )} with ${
              emailContent.officeName
            }. You can easily review your bill and submit your payment using the link below: \n\n${
              emailContent.statementLink
            }\n\nIf this payment has already been made or you think this message was sent in error, no further action is needed.\n\Thank you for your attention to this matter.\n\nReply STOP to opt-out.`
          );
          break;
        case "urgent":
          setEmailTemplate(getUrgentEmailTemplate(emailContent));
          setValue("subject", `Action Needed: Outstanding Balance with ${office?.name}`);
          setValue(
            "message",
            `Hi ${
              emailContent.patientName
            }, \n\nWe are reaching out regarding your overdue balance of $${formatNumber(
              emailContent.balanceAmount,
              "balance"
            )} with ${
              emailContent.officeName
            }. Please use the link below to view your statement and submit payment as soon as possible: \n\n${
              emailContent.statementLink
            }\n\nIf you’ve recently made this payment, thank you! Otherwise, please address this matter at your earliest convenience to avoid any further action.\n\nThank you,\n\nReply STOP to opt-out.`
          );
          break;
        case "final":
          setEmailTemplate(getFinalEmailTemplate(emailContent));
          setValue("subject", `Final Notice: Immediate Payment Required for Your Account`);
          setValue(
            "message",
            `Hi ${
              emailContent.patientName
            }, \n\nThis is a final notice regarding your unpaid balance of $${formatNumber(
              emailContent.balanceAmount,
              "balance"
            )} with ${
              emailContent.officeName
            }. Immediate payment is required to prevent further escalation of this matter. Please review your bill and settle your account using the link below: \n\n${
              emailContent.statementLink
            }\n\nIf this payment has already been made, please disregard this message. Otherwise, prompt action is needed.\n\nThank you,\n\nReply STOP to opt-out.`
          );
          break;
        case "custom":
          setEmailTemplate(
            getCustomRequestEmailTemplate({
              ...emailContent,
              subject: watchSubject,
              message: watchMessage,
            })
          );
          // setValue(
          //   "message",
          //   `Hi ${singlePatient?.firstName || "Patient"} ${
          //     singlePatient?.lastName || "Name"
          //   }, please pay your balance of $${watchShowAmount} at your earliest convenience. Thank you!`
          // );
          // setValue("subject", "We're kindly requesting payment");
          break;
        default:
          break;
      }
    }
  }, [emailContent, template]);

  useEffect(() => {
    if (template) {
      if (template.value === "custom") {
        setValue(
          "message",
          `Hi ${singlePatient?.firstName || "Patient"} ${
            singlePatient?.lastName || "Name"
          }, please pay your balance of $${formatNumber(
            watchShowAmount,
            "balance"
          )} at your earliest convenience. Thank you!`
        );
        setValue("subject", "We're kindly requesting payment");
      }
    }
  }, [template, singlePatient, watchShowAmount]);

  return (
    <div>
      <Form className="w-full" form={form} handleSubmit={handleSubmit}>
        <div className="space-y-3 pb-6">
          <div className="mt-6 flex flex-col gap-5">
            <div className="">
              <Label>Account</Label>
              <div className="relative w-full">
                <Select
                  placeholder="Search Account"
                  className="rounded-xl"
                  value={patient ? patientOptions?.find((p) => p.value === patient) : null}
                  options={patientOptions}
                  onInputChange={(e) => setSearch(e)}
                  isLoading={isLoading}
                  onChange={(option) => {
                    if (option) {
                      setPatient(option.value);
                    }
                  }}
                />
              </div>
            </div>
            <div className="">
              <Label>Templates</Label>
              <div className="relative w-full">
                <Select
                  placeholder="Search Template"
                  className="rounded-xl"
                  value={PRTemplateOptions.find((p) => p.value === template?.value)}
                  options={PRTemplateOptions}
                  onChange={(option) => {
                    if (option) {
                      setTemplate(option);
                    }
                  }}
                />
              </div>
            </div>
            <div className="">
              <Label>Amount Requested</Label>
              <TextField
                type="number"
                className="relative rounded-lg"
                label=""
                step={0.01}
                min={0}
                addOnLeading={<DollarIcon />}
                {...register("amount", { valueAsNumber: true })}
              />
            </div>
            <div className="">
              <Controller
                name="onEmail"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex items-center justify-between">
                    <Label className="m-0 text-gray-600">Email Address</Label>
                    <Switch label="" checked={value} onCheckedChange={onChange} />
                  </div>
                )}
              />
              <div className="relative">
                <TextField
                  label=""
                  disabled={!watchOnEmail}
                  className="w-full"
                  addOnLeading={<MailIcon />}
                  {...register("email")}
                />
              </div>
            </div>
            <div className="">
              <Controller
                name="onText"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex items-center justify-between">
                    <Label className="m-0 text-gray-600">Phone Number</Label>
                    <Switch label="" checked={value} onCheckedChange={onChange} />
                  </div>
                )}
              />
              <div className="relative">
                <TextField
                  label=""
                  disabled={!watchOnText}
                  className="w-full"
                  addOnLeading={<MobilePhoneIcon />}
                  {...register("phone")}
                  placeholder="(555) 555-5555"
                  onChange={(e) => setValue("phone", formatPhoneNumber(e.target.value))}
                />
              </div>
            </div>
            <div className="">
              <Label className="flex items-center gap-2">
                Email Subject
                <Tooltip
                  content="The email subject will only appear when the message is sent as an email; it will not appear in a text message."
                  className="z-50 max-w-[500px]">
                  <span>
                    <InformationIcon />
                  </span>
                </Tooltip>
              </Label>
              <TextField
                label=""
                className="w-full"
                disabled={template?.value !== "custom"}
                {...register("subject")}
              />
            </div>
            <div className="flex flex-col gap-2 md:col-span-1">
              <div className="w-full">
                <TextAreaField
                  label="Message Content"
                  rows={template?.value !== "custom" ? 15 : 5}
                  disabled={template?.value !== "custom"}
                  readOnly={template?.value !== "custom"}
                  className="w-full disabled:bg-gray-50"
                  {...register("message")}
                />
              </div>
            </div>
            <Button
              className="flex h-10 justify-center bg-black text-white hover:bg-black hover:text-white"
              type="submit"
              loadingTextClassName="text-black"
              loading={loading}>
              Send ${formatNumber(watchShowAmount, "balance")} Payment Request
            </Button>
            <div className="border-t pt-4">
              <Dropdown modal={false}>
                <DropdownMenuTrigger asChild data-testid="event-type-options-">
                  <div className="flex h-10 w-full cursor-pointer items-center justify-center gap-2 rounded-lg border bg-transparent bg-white text-sm text-gray-900 hover:bg-white hover:text-gray-900">
                    Payment Links <ChevronDownIcon className="h-4 w-4" />
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuContent
                    style={{
                      marginTop: "10px",
                      marginLeft: "0px",
                      zIndex: 1000,
                    }}>
                    <DropdownMenuItem className="w-[600px] rounded-lg">
                      <div className="flex w-full justify-between py-3 px-2">
                        <span>{singlePatient?.firstName}&apos;s Statement Link</span>
                        <div className="flex gap-2">
                          <Link
                            href={statementLink || ""}
                            target="_blank"
                            className="cursor-pointer hover:underline">
                            View Link
                          </Link>
                          {template?.value === "custom" && (
                            <span
                              className="cursor-pointer hover:underline"
                              onClick={handleCopyStatementLink}>
                              Copy Link
                            </span>
                          )}
                        </div>
                      </div>
                    </DropdownMenuItem>
                    <DropdownMenuItem className="w-full rounded-lg">
                      <div className="flex w-full justify-between py-3 px-2">
                        <span>Empty Payment Link</span>
                        <div className="flex gap-2">
                          <Link href={paymentLink} target="_blank" className="cursor-pointer hover:underline">
                            View Link
                          </Link>
                          {template?.value === "custom" && (
                            <span className="cursor-pointer hover:underline" onClick={handleCopyLink}>
                              Copy Link
                            </span>
                          )}
                        </div>
                      </div>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenuPortal>
              </Dropdown>
              <p className="mt-3 text-xs leading-5 text-gray-600">
                The account statement link is included in our templates. For custom templates, you&apos;ll
                need to manually add the statement or payment link. Click the &apos;Payment Links&apos; button
                above to view a dropdown of links and copy them into your message.
              </p>
            </div>
          </div>
        </div>
      </Form>

      <PaymentSuccessDialog
        title="Payment Request Sent"
        description="Your payment request has been sent."
        amount={watchShowAmount}
        account={singlePatient?.firstName + " " + singlePatient?.lastName}
        date={new Date().toLocaleDateString()}
        source="Payment Request"
        open={paymentSuccessDialogOpen}
        handler={() => {
          setPaymentSuccessDialogOpen(false);
          reset();
          setPatient(undefined);
        }}
        onClose={() => {
          setPaymentSuccessDialogOpen(false);
          setPaymentDialog(false);
          setPatient(undefined);
          reset();
        }}
        email={singlePatient?.email || ""}
        phone={singlePatient?.phoneNumbers[0]?.number || ""}
        footer={
          <p className="text-center text-sm text-gray-500">
            The request was sent to {singlePatient?.firstName} {singlePatient?.lastName}&apos;s{" "}
            {singlePatient?.phoneNumbers[0]?.number ? singlePatient?.phoneNumbers[0]?.number + " and " : " "}
            {singlePatient?.email}
          </p>
        }
      />
    </div>
  );
}
