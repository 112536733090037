import {
  AccountableType,
  PaymentSource,
  MessageLogType,
  PaymentType,
  PracticeManagement,
  OfficeStatus,
  MembershipType,
  PaymentCycle,
  TransactionStatus,
  MessageSource,
  ClaimStatus,
  ClaimSubStatus,
} from "@prisma/client";

import { formatPMS } from "@calcom/lib/format";

type OutreachOptionsType = {
  value?: MessageLogType;
  label: string;
};

type StringOptionsType = {
  value: string;
  label: string;
};

type MembershipOptionType = {
  value: MembershipType;
  label: string;
};

export enum MembershipPendingReason {
  StartDate = "Start Date",
  PatientApproval = "Patient Approval",
}

export const OutreachOptions: Array<OutreachOptionsType> = [
  { value: undefined, label: "Communication Type" },
  { value: MessageLogType.SMS, label: "SMS" },
  { value: MessageLogType.EMAIL, label: "EMAIL" },
];

type ChargeOptionsType = {
  value?: PaymentType;
  label: string;
};

export const ChargeOptions: Array<ChargeOptionsType> = [
  { value: undefined, label: "All Types" },
  { value: PaymentType.PROMPTED, label: "Prompted" },
  { value: PaymentType.PROCESSED, label: "Processed" },
];

export const PaymentPlanOptions: Array<{
  value?: string;
  label: string;
}> = [
  { value: "active", label: "All Plans" },
  { value: "created-date", label: "Created Date" },
];

export const TransactionStatusOptions: Array<{ value?: TransactionStatus; label: string }> = [
  { value: undefined, label: "Transaction State" },
  { value: TransactionStatus.SUCCESSFUL, label: "Success" },
  { value: TransactionStatus.REFUND, label: "Refund" },
  { value: TransactionStatus.FAILED, label: "Failed" },
  { value: TransactionStatus.DISPUTE, label: "Dispute" },
];

export const MessageSourceOptions: Array<{ value?: MessageSource; label: string }> = [
  { value: MessageSource.AUTOMATEDAR, label: "Auto Collection" },
  { value: MessageSource.MEMBERSHIP, label: "Subscription" },
  { value: MessageSource.PAYMENTPLAN, label: "Payment Plan" },
  { value: MessageSource.PAYMENT_REQUEST, label: "Payment Request" },
  { value: MessageSource.CARD_REQUEST, label: "Card Request" },
  { value: MessageSource.CONFIRMATION, label: "Confirmation" },
];

export const PaymentSourceOptions: Array<{ value?: PaymentSource; label: string }> = [
  { value: undefined, label: "All Transaction Source" },
  { value: PaymentSource.AUTOMATEDAR, label: "Auto Collection" },
  { value: PaymentSource.PAYMENTPLAN, label: "Payment Plan" },
  { value: PaymentSource.MEMBERSHIP, label: "Subscription" },
  { value: PaymentSource.VIRTUAL, label: "Virtual Payment" },
  { value: PaymentSource.TERMINAL, label: "Terminal Payment" },
  { value: PaymentSource.ONLINE, label: "Online Payment" },
];

type TransactionOptionType = {
  value?: PaymentType;
  label: string;
};

export const TransactionsOptions: Array<TransactionOptionType> = [
  { value: undefined, label: "All Transactions Source" },
  { value: PaymentType.PROMPTED, label: "Prompted" },
  { value: PaymentType.PROCESSED, label: "Processed" },
];

export const InvoiceStatusOptions: Array<{ value: string | undefined; label: string }> = [
  { value: undefined, label: "Filter by Status" },
  { value: "Submitted", label: "Submitted" },
  { value: "Drafted", label: "Drafted" },
  { value: "HasAmount", label: "Amount Greater Than $0.01" },
];

export const RecordOptions: Array<{ value: string | undefined; label: string }> = [
  { value: undefined, label: "Recorded Status" },
  { value: "recorded", label: "Recorded" },
  { value: "not_recorded", label: "Not Recorded" },
  { value: "not_applicable", label: "Not Applicable" },
];

export const InsuranceTypeOptions: Array<{ value: string | undefined; label: string }> = [
  { value: "claim", label: "Insurance Claims" },
  { value: "payments", label: "Insurance Payments" },
];

export const PercentageOptions: Array<{ value: number | undefined; label: string }> = [
  { value: 10, label: "10%" },
  { value: 20, label: "20%" },
];

export const ClaimStatusOptions: Array<{ value: ClaimStatus | undefined; label: string }> = [
  { value: undefined, label: "Filter By Claim Status" },
  { value: ClaimStatus.SENT, label: "Sent" },
  { value: ClaimStatus.RECEIVED, label: "Received" },
  { value: ClaimStatus.APPROVED, label: "Approved" },
  { value: ClaimStatus.PAID, label: "Paid" },
  { value: ClaimStatus.REJECTED, label: "Rejected" },
  { value: ClaimStatus.CANCELED, label: "Canceled" },
];

export const PaymentStatusOptions: Array<{ value: boolean | undefined; label: string }> = [
  { value: undefined, label: "Filter By Payment Status" },
  { value: true, label: "Recorded" },
  { value: false, label: "Non Recorded" },
];

export const ClaimAmountOptions: Array<{ value: string | undefined; label: string }> = [
  { value: "0_50", label: "$0.00 - $50.00" },
  { value: "50_500", label: "$50.01 - $500.00" },
  { value: "500_1500", label: "500.01 - $1,500" },
  { value: ">1500", label: "$1,500.01 - and up" },
];

export const OutstandingTypeOptions: Array<{ value: string | undefined; label: string }> = [
  { value: "outstanding", label: "Outstanding Accounts" },
  // { value: "insurance", label: "Insurance Claims" },
  // { value: "autocollection", label: "Auto Collection Accounts" },
];

export const StatementTypeOptions: Array<{ value: string | undefined; label: string }> = [
  { value: "statement", label: "Statement Table" },
  { value: "accounts_due", label: "Accounts Due" },
];

export const OutstandingBalanceTypeOptions: Array<{ value: string | undefined; label: string }> = [
  { value: undefined, label: "All Balance Types" },
  { value: "balance", label: "Account Balance" },
  { value: "insurance", label: "Insurance Balance" },
];

export const AgingBucketOptions: Array<{ value: string | undefined; label: string }> = [
  { value: "0_30", label: "0 - 30" },
  { value: "30_60", label: "30 - 60" },
  { value: "60_90", label: "60 - 90" },
  { value: "90_120", label: "90 - 120" },
  { value: "120_150", label: "120 - 150" },
  { value: "150_180", label: "150 - 180" },
  { value: "180_360", label: "180 - 360" },
  { value: "360_720", label: "360 - 720" },
];

export const ProcessPaymentOptions: Array<MembershipOptionType> = [
  { value: MembershipType.YEARLY, label: "Yearly" },
  { value: MembershipType.MONTHLY, label: "Monthly" },
];

export const ProcessPaymentExtendedOptions: Array<{ value: PaymentCycle; label: string }> = [
  { value: PaymentCycle.WEEKLY, label: "Weekly" },
  { value: PaymentCycle.BIWEEKLY, label: "Biweekly" },
  { value: PaymentCycle.MONTHLY, label: "Monthly" },
  { value: PaymentCycle.YEARLY, label: "Yearly" },
];

export const PaymentPlanPeriodOptions: Array<{ value: PaymentCycle; label: string }> = [
  { value: PaymentCycle.WEEKLY, label: "Weekly" },
  { value: PaymentCycle.BIWEEKLY, label: "Biweekly" },
  { value: PaymentCycle.MONTHLY, label: "Monthly" },
  { value: PaymentCycle.YEARLY, label: "Yearly" },
];

export const PaymentCountOptions: Array<{ value: number; label: string }> = [
  { value: 2, label: "2 installments" },
  { value: 3, label: "3 installments" },
  { value: 4, label: "4 installments" },
  { value: 5, label: "5 installments" },
  { value: 6, label: "6 installments" },
  { value: 7, label: "7 installments" },
  { value: 8, label: "8 installments" },
  { value: 9, label: "9 installments" },
  { value: 10, label: "10 installments" },
  { value: 12, label: "12 installments" },
  { value: 15, label: "15 installments" },
  { value: 20, label: "20 installments" },
  { value: 30, label: "30 installments" },
];

export const EnrollmentFeeOptions: Array<{ value: number; label: string }> = [
  { value: 0, label: "No Fee" },
  { value: 1, label: "1%" },
  { value: 2, label: "2%" },
  { value: 3, label: "3%" },
  { value: 4, label: "4%" },
  { value: 5, label: "5%" },
  { value: 6, label: "6%" },
  { value: 7, label: "7%" },
  { value: 8, label: "8%" },
  { value: 9, label: "9%" },
  { value: 10, label: "10%" },
];

export const InsightOptions: Array<StringOptionsType> = [
  { value: "transactions", label: "Transactions Table" },
  { value: "outreach", label: "Messages Table" },
];

export type OfficeOptionsType = {
  value?: OfficeStatus;
  label: string;
};

export const OfficeFilterOptions: Array<OfficeOptionsType> = [
  { value: undefined, label: "Filter by Location Status" },
  { value: OfficeStatus.ACTIVE, label: "Active" },
  { value: OfficeStatus.INACTIVE, label: "Inactive" },
  { value: OfficeStatus.PAUSE, label: "Pause" },
  { value: OfficeStatus.ONBOARDED, label: "Onboarded" },
];

export const PatientsOptions = [
  { value: undefined, label: "Sort Directory By :" },
  { value: 1, label: "Only with balance" },
];

export const RowsPerPageOptions = [
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

export const DateRangeOptions = [
  { value: 0, label: "All Time" },
  { value: 1, label: "Month to Date" },
  { value: 2, label: "Today" },
  { value: 3, label: "Past 7 Days" },
  { value: 4, label: "Last Month" },
];

export type ReportOptionType = {
  value: string;
  label: string;
  type: PracticeManagement;
};

export const ReportUploadOptions: Array<ReportOptionType> = [
  { value: "winoms_payment", label: "Payment", type: PracticeManagement.WINOMS },
  { value: "winoms_patient", label: "Patient", type: PracticeManagement.WINOMS },
  { value: "winoms_special_patient", label: "Patient", type: PracticeManagement.WINOMS_SPECIAL },
  { value: "winoms_appointment", label: "Appointment", type: PracticeManagement.WINOMS },
  { value: "dentrix", label: "Dentrix", type: PracticeManagement.DENTRIX },
  {
    value: "dentrix_ascend_transaction",
    label: "Transaction Detail",
    type: PracticeManagement.DENTRIX_ASCEND,
  },
  { value: "dentrix_ascend", label: "Aged Receivable", type: PracticeManagement.DENTRIX_ASCEND },
  { value: "dentrix_aged_receivable", label: "Aged Receivable", type: PracticeManagement.DENTRIX_NI },
  { value: "eaglesoft_patient", label: "Patient", type: PracticeManagement.EAGLESOFT_NI },
  { value: "eaglesoft_aged_receivable", label: "Aged Receivable", type: PracticeManagement.EAGLESOFT_NI },
  { value: "eaglesoft_aged_receivable", label: "Aged Receivable", type: PracticeManagement.EAGLESOFT },
  { value: "curve_appointment", label: "Appointment", type: PracticeManagement.CURVE },
  { value: "curve_payment", label: "Payment", type: PracticeManagement.CURVE },
  { value: "curve-balance", label: "Aged Receivable", type: PracticeManagement.CURVE },
  { value: "curve-light-balance", label: "Light Patient Demo", type: PracticeManagement.CURVE },
  { value: "fuse_patient_seen", label: "Patient Seen", type: PracticeManagement.FUSE },
  { value: "fuse_patient_last_seen", label: "Patient Last Service", type: PracticeManagement.FUSE },
  { value: "fuse_aged_receivable", label: "Aged Receivable", type: PracticeManagement.FUSE },
  { value: "opendental_aged_receivable", label: "Aged Receivable", type: PracticeManagement.OPENDENTAL },
  { value: "opendental_patient_seen", label: "Patient Seen", type: PracticeManagement.OPENDENTAL_NI },
  { value: "opendental_aged_receivable", label: "Aged Receivable", type: PracticeManagement.OPENDENTAL_NI },
  // { value: "opendental_insurance_claim", label: "Insurance Claim", type: PracticeManagement.OPENDENTAL },
  // { value: "denticon_patient", label: "Patient", type: PracticeManagement.DENTICON },
  { value: "denticon_aged_receivable", label: "Aged Receivable", type: PracticeManagement.DENTICON },
  { value: "softdent_patient", label: "Patient", type: PracticeManagement.SOFTDENT },
  { value: "softdent_aged_receivable", label: "Aged Receivable", type: PracticeManagement.SOFTDENT },
  { value: "sensicloud_patient", label: "Patient", type: PracticeManagement.SENSI_CLOUD },
  { value: "dns_patient", label: "Patient", type: PracticeManagement.DNS },
  { value: "dns_aged_receivable", label: "Aged Receivable", type: PracticeManagement.DNS },
  { value: "dentimax_patient", label: "Patient", type: PracticeManagement.DENTIMAX },
  { value: "dentimax_aged_receivable", label: "Aged Receivable", type: PracticeManagement.DENTIMAX },
  { value: "cloud9_aged_receivable", label: "Aged Receivable", type: PracticeManagement.CLOUD9 },
];

type GroupReportOptionType = {
  value: string;
  label: string;
  groupId: number;
};
export const GroupReportOptions: Array<GroupReportOptionType> = [
  { value: "qsi-claims", label: "QSI Insurance Claims", groupId: 3 },
];

export const PearlyUploadOptions = [
  { value: "pearly_processed", label: "PAYMENT PROCESSED" },
  { value: "pearly_prompted", label: "PAYMENT PROMPTED" },
  { value: "pearly_arstatus", label: "AR STATUS" },
];

export const timeZoneList = [
  { value: "America/New_York", label: "Eastern Time" },
  { value: "America/Chicago", label: "Central Time" },
  { value: "America/Denver", label: "Mountain Time" },
  { value: "America/Phoenix", label: "Mountain Time (no DST)" },
  { value: "America/Los_Angeles", label: "Pacific Time" },
  { value: "America/Anchorage", label: "Alaska Time" },
  { value: "America/Adak", label: "Hawaii-Aleutian" },
  { value: "Pacific/Honolulu", label: "Hawaii-Aleutian Time (no DST)" },
];

export const practiceList = Object.entries(PracticeManagement).map(([key, value]) => ({
  value: value,
  label: formatPMS(key as PracticeManagement),
}));

export const SubscriptionPlanStatusOptions = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
  // { value: "draft", label: "Draft" },
];

export const PaymentOptions = [
  { value: "virtual", label: "Virtual Payment" },
  { value: "terminal", label: "Terminal Payment" },
  { value: "insurance", label: "Insurance Payment" },
  { value: "request", label: "Payment Request" },
  // { value: "link", label: "Payment Link" },
  // { value: "paymentplan", label: "Enroll in Payment Plan" },
  // { value: "subscription", label: "Enroll in Subscription" },
];

export const CommunicationOptions = [
  { value: "0-100", label: "0 - 100" },
  { value: "101-500", label: "101 - 500" },
  { value: "501-5000", label: "501 - 5,000" },
  { value: "50001", label: "5,0001 - Up" },
];

export const OutstandingOptions = [
  { value: "0-50000", label: "0 - $50,000" },
  { value: "50001-100000", label: "$50,001 - $100,000" },
  { value: "100001-500000", label: "$100,001 - $500,000" },
  { value: "500001", label: "$500,001 - Up" },
];

export const GrossVolumeOptions = [
  { value: "0-10000", label: "0 - $10,000" },
  { value: "10001-50000", label: "$10,001 - $50,000" },
  { value: "50001-100000", label: "$50,001 - $100,000" },
  { value: "10001-250000", label: "$100,001 - $250,000" },
  { value: "250001-500000", label: "$250,001 - $500,000" },
  { value: "500001", label: "$500,001 - Up" },
];

export const MissingPaymentSourceOptions = [
  { value: undefined, label: "Integration" },
  { value: PaymentSource.AUTOMATEDAR, label: "Auto Collection" },
  { value: PaymentSource.PAYMENTPLAN, label: "Payment Plan" },
  { value: PaymentSource.MEMBERSHIP, label: "Subscription" },
  { value: PaymentSource.VIRTUAL, label: "Virtual Payment" },
  { value: PaymentSource.TERMINAL, label: "Terminal Payment" },
  { value: PaymentSource.ONLINE, label: "Online Payment" },
  { value: PaymentSource.INSURANCE, label: "Insurance Payment" },
];

export const AdjustmentTypeOptions = [
  { value: AccountableType.PATIENT, label: "Payment Adjustment" },
  { value: AccountableType.INSURANCE_PLAN, label: "Insurance Adjustment" },
];

export const AdjustmentDirectionOptions = [
  { value: -1, label: "Credit" },
  { value: 1, label: "Debit" },
];

export const PayoutStatusOptions = [
  { value: undefined, label: "All Status" },
  { value: "APPROVED", label: "Approved" },
  { value: "AWAITING_APPROVAL", label: "Awaiting" },
];

export type PRTemplateOption = {
  value: "basic" | "reminder" | "follow-up" | "urgent" | "final" | "encourage" | "custom";
  label: string;
};

export const PRTemplateOptions: PRTemplateOption[] = [
  { value: "basic", label: "Basic Payment Request" },
  { value: "reminder", label: "Friendly Reminder" },
  { value: "follow-up", label: "Professional Follow-Up" },
  { value: "urgent", label: "Urgent Payment Request" },
  { value: "final", label: "Final Notice" },
  { value: "encourage", label: "Friendly Encouragement" },
  { value: "custom", label: "Custom" },
];

export const StatementOptions = [{ value: "default", label: "Default Template" }];

export const ClaimSubStatusOptions = [
  {
    value: ClaimSubStatus.CLAIM_NOF_ISR_INCORRECT,
    label: "Claim not on file (NOF) - insurance demographics missing/incorrect",
  },
  {
    value: ClaimSubStatus.CLAIM_NOF_PAT_INCORRECT,
    label: "Claim not on file (NOF) - patient demographics missing/incorrect",
  },
  { value: ClaimSubStatus.CLEARINGHOUSE_ERROR, label: "Clearinghouse error" },
  { value: ClaimSubStatus.CODING_ISSUE, label: "Coding issue" },
  { value: ClaimSubStatus.CREDENTIALING_ISSUE, label: "Credentialing issue" },
  { value: ClaimSubStatus.ISR_TERMINATED, label: "Insurance terminated" },
  { value: ClaimSubStatus.MISSING_DOC, label: "Missing documentation" },
];
