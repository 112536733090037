type Props = {
  patientName?: string | null;
  officeName?: string | null;
  link?: string | null;
  officeNumber?: string | null;
  officeEmail?: string | null;
  logo?: string | null;
  officeAddress?: string | null;
  senderName?: string | null;
  senderAddress?: string | null;
  headerColor?: string | null;
  buttonColor?: string | null;
  statementLink?: string | null;
  balanceAmount?: number | null;
};

export const getFollowUpSMSTemplate = (props: Props) => {
  const { patientName, officeName, link, officeNumber, officeEmail } = props;

  return `A membership has been created for you.\nHello ${patientName}, we kindly request that you complete your membership enrollment by reviewing, authorizing, and adding a payment method.\n
  ${link}\nIf you have any questions about this membership, please contact us below.\n${officeNumber}\n${officeEmail}\n\n${officeName}`;
};

export const getReminderEmailTemplate = (props: Props) => {
  const {
    patientName,
    officeName,
    link,
    officeNumber,
    officeEmail,
    logo,
    officeAddress,
    senderName,
    senderAddress,
    headerColor,
    buttonColor,
    statementLink,
    balanceAmount,
  } = props;

  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html data-editor-version="2" class="sg-campaigns"
  xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport"
      content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1">
    <meta http-equiv="X-UA-Compatible" content="IE=Edge">
    <style type="text/css">
      body, p, div {
        font-family: arial,helvetica,sans-serif;
        font-size: 14px;
      }
      body {
        color: #000000;
      }
      body a {
        color: #1188E6;
        text-decoration: none;
      }
      p { margin: 0; padding: 0; }
      table.wrapper {
        width:100% !important;
        table-layout: fixed;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }
      img.max-width {
        max-width: 100% !important;
      }
      .column.of-2 {
        width: 50%;
      }
      .column.of-3 {
        width: 33.333%;
      }
      .column.of-4 {
        width: 25%;
      }
      ul ul ul ul  {
        list-style-type: disc !important;
      }
      ol ol {
        list-style-type: lower-roman !important;
      }
      ol ol ol {
        list-style-type: lower-latin !important;
      }
      ol ol ol ol {
        list-style-type: decimal !important;
      }
      @media screen and (max-width:480px) {
        .preheader .rightColumnContent,
        .footer .rightColumnContent {
          text-align: left !important;
        }
        .preheader .rightColumnContent div,
        .preheader .rightColumnContent span,
        .footer .rightColumnContent div,
        .footer .rightColumnContent span {
          text-align: left !important;
        }
        .preheader .rightColumnContent,
        .preheader .leftColumnContent {
          font-size: 80% !important;
          padding: 5px 0;
        }
        table.wrapper-mobile {
          width: 100% !important;
          table-layout: fixed;
        }
        img.max-width {
          height: auto !important;
          max-width: 100% !important;
        }
        a.bulletproof-button {
          display: block !important;
          width: auto !important;
          font-size: 80%;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        .columns {
          width: 100% !important;
        }
        .column {
          display: block !important;
          width: 100% !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
        .social-icon-column {
          display: inline-block !important;
        }
      }
    </style>
  </head>
  <body>
    <center class="wrapper" data-link-color="#1188E6"
      data-body-style="font-size:14px; font-family:arial,helvetica,sans-serif; color:#000000; background-color:#F3F4F6;">
      <div class="webkit">
        <table cellpadding="0" cellspacing="0" border="0" width="100%"
          class="wrapper" bgcolor="#F3F4F6">
          <tr>
            <td valign="top" bgcolor="#F3F4F6" width="100%">
              <table width="100%" role="content-container" class="outer"
                align="center" cellpadding="0" cellspacing="0" border="0">
                <tr>
                  <td width="100%">
                    <table width="100%" cellpadding="0" cellspacing="0"
                      border="0">
                      <tr>
                        <td>
                          <table width="100%" cellpadding="0" cellspacing="0"
                            border="0" style="width:100%; max-width:500px;"
                            align="center">
                            <tr>
                              <td role="modules-container"
                                style="padding:0px 0px 0px 0px; color:#000000; text-align:left;"
                                bgcolor="#ffffff" width="100%"
                                align="left"><table
                                  class="module preheader preheader-hide"
                                  role="module" data-type="preheader" border="0"
                                  cellpadding="0" cellspacing="0" width="100%"
                                  style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
                                  <tr>
                                    <td role="module-content">
                                      <p></p>
                                    </td>
                                  </tr>
                                </table>
                                <table class="wrapper" role="module" data-type="image" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="2acb7ff8-50ad-49a1-b150-35fa8c855932">
                                  <tbody>
                                    <tr>
                                      <td style="font-size:6px; line-height:10px;  background-color: ${headerColor}" valign="top" align="center">
                                        <table class="wrapper">
                                          <tbody>
                                            <tr>
                                                                <td style="padding: 11px 25px;border-radius: 0.5rem; font-size: 14px; color: white;">${officeAddress}</td>

                                          </tbody>
                                        </table>
                                      
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table class="wrapper" role="module"
                                  data-type="image" border="0" cellpadding="0"
                                  cellspacing="0" width="100%"
                                  style="table-layout: fixed; background-color: ${headerColor};"
                                  data-muid="2acb7ff8-50ad-49a1-b150-35fa8c855932">
                                  <tbody>
                                    <tr>
                                      <td
                                        style="font-size:6px; line-height:10px; padding:10px 20px 10px 50px; border-top-left-radius: 10px; border-top-right-radius: 10px; background-color: #F9FAFA; border-bottom: 1px rgb(229, 231, 235) solid;"
                                        valign="top" align="center">
                                        <table class="wrapper">
                                          <tbody>
                                            <tr>
                                              <td
                                                style="border-radius: 0.5rem; width: 150px;"><img
                                                  class="max-width" border="0"
                                                  style="display:block; height: 3.5rem;  color:#000000; text-decoration:none; font-family:Helvetica, arial, sans-serif; font-size:16px;"
                                                  data-proportionally-constrained="true"
                                                  data-responsive="true"
                                                  src="${logo}"></td>
                                              <td
                                                style="padding: 10px 0 10px 20px; text-align: right;"><p
                                                  style="font-weight: 700; font-size: 14px;  line-height: 23px;">${officeName}</p>
                                                <p
                                                  style="font-size: 12px; color: rgb(55, 55, 55);  line-height: 16px;">Phone:
                                                  ${officeNumber}</p>
                                              </tbody>
                                            </table>

                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table class="module" role="module"
                                      data-type="text" border="0"
                                      cellpadding="0" cellspacing="0"
                                      width="100%" style="table-layout: fixed;"
                                      data-muid="f3d09df6-b9ad-49f7-a749-e52b4a61b9d3"
                                      data-mc-module-version="2019-10-22">
                                      <tbody>
                                        <tr>
                                          <td
                                            style="padding:18px 50px 15px 50px; line-height:23px; text-align:inherit;"
                                            height="100%" valign="top" bgcolor
                                            role="module-content"><div><h3
                                                style="text-align: left">Quick Reminder: Your ${officeName} Bill is Ready</h3>
                                              <div
                                                style="font-family: inherit; text-align: left">Hi
                                                ${patientName}
                                                <br>
                                                <br>
                                                We hope this message finds you well! It looks like there’s a balance of $${balanceAmount} on your account with ${officeName}. You can review your bill and make a payment by clicking the link below:
                                                <br><br>
                                                ${statementLink}
                                                <br><br>
                                                If you’ve already settled this balance or received this in error, please feel free to disregard it.
                                                <br><br>
                                                Thank you, and have a wonderful day!
                                                <br>
                                                <br>
                                                Reply STOP to opt-out.
                                                <br>
                                              </div>
                                              <div
                                                style="font-family: inherit"><br></div><div></div></div></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <div data-role="module-unsubscribe"
                                      class="module" role="module"
                                      data-type="unsubscribe"
                                      style="background-color:#E2E2E2; color:#000000; font-size:12px; line-height:20px; padding:16px 16px 16px 16px; text-align:Center;"
                                      data-muid="4e838cf3-9892-4a6d-94d6-170e474d21e5"><div
                                        class="Unsubscribe--addressLine"><p
                                          class="Unsubscribe--senderName"
                                          style="font-size:12px; line-height:20px;">${senderName}</p><p
                                          style="font-size:12px; line-height:20px;"><span
                                            class="Unsubscribe--senderAddress">${senderAddress}</span></p></div><p
                                        style="font-size:12px; line-height:20px;"><a
                                          class="Unsubscribe--unsubscribeLink"
                                          href="{{{unsubscribe}}}"
                                          target="_blank"
                                          style="color:#4093CA;">Unsubscribe</a>
                                        - <a
                                          href="{{{unsubscribe_preferences}}}"
                                          target="_blank"
                                          class="Unsubscribe--unsubscribePreferences"
                                          style="color:#4093CA;">Unsubscribe
                                          Preferences</a></p></div></td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </center>
      </body>
    </html>`;
};
