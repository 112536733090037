import { useAutoAnimate } from "@formkit/auto-animate/react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as RadioGroup from "@radix-ui/react-radio-group";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { formatCardBrand } from "@calcom/lib/format";
import type { Brands } from "@calcom/lib/getCardBrand";
import { getCardBrand } from "@calcom/lib/getCardBrand";
import type { RouterOutputs } from "@calcom/trpc/react";
import { trpc } from "@calcom/trpc/react";
import { Button, Label, TextAreaField, TextField, Form, showToast, Select, Switch } from "@calcom/ui";

import { calculateFee } from "@lib/finix";
import { formatNumber } from "@lib/practice/helper";

import { DollarIcon } from "@components/Icons";
import PaymentSuccessDialog from "@components/patients/PaymentSuccessDialog";
import PaymentForm from "@components/payment/PaymentForm";

type Props = {
  patientId?: number;
  officeId: number;
  appId: string;
  env: string;
  setPaymentDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormValues = {
  cardId: number;
  amount: number;
  note: string;
  enableSurcharge: boolean;
  discountAmount: number;
  discountPercentage: number;
};

type Card = RouterOutputs["viewer"]["patients"]["getCardList"][number];

export default function VirtualPayment({ patientId, officeId, appId, env, setPaymentDialog }: Props) {
  const [patient, setPatient] = useState<number | undefined>(patientId);
  const [surchargeApplied, setSurchargeApplied] = useState(false);

  const { data: office } = trpc.viewer.office.getById.useQuery({
    officeId: officeId,
  });

  const { data: cardList } = trpc.viewer.patients.getCardList.useQuery(
    {
      patientId: Number(patient),
      officeId,
    },
    { enabled: !!patient }
  );

  const cardOptions = cardList?.map((card) => ({
    label: `${
      card.brand
        ? formatCardBrand(card.brand as Brands) +
          " Last 4: " +
          card.lastFour +
          ", Exp " +
          card.expMonth +
          "/" +
          card.expYear +
          `${card.selected ? " (Default)" : ""}`
        : "Bank : " + card.maskedAccountNumber + `${card.selected ? " (Default)" : ""}`
    }`,
    value: card.id,
  }));

  const defaultCardId = cardList?.find((card) => card.selected)?.id;

  const [animationParentRef] = useAutoAnimate<HTMLDivElement>();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const [paymentOption, setPaymentOption] = useState("add-new");

  const [paymentSuccessDialogOpen, setPaymentSuccessDialogOpen] = useState(false);

  const [card, setCard] = useState<Card | undefined>(undefined);
  const [cardOption, setCardOption] = useState<{ label: string; value: number }>();
  const [isACHPayment, setIsACHPayment] = useState(false);
  const [fee, setFee] = useState(0);

  useEffect(() => {
    if (cardOptions) {
      const defaultCardIndex = cardOptions.findIndex((card) => card.value === defaultCardId);

      setCardOption(cardOptions[defaultCardIndex < 0 ? 0 : defaultCardIndex]);
    }
  }, [cardOptions]);

  const form = useForm<FormValues>({
    defaultValues: {
      enableSurcharge: true,
      amount: 0,
      discountAmount: 0,
      discountPercentage: 0,
    },
    resolver: zodResolver(
      z.object({
        cardId: z.number(),
        amount: z.number().min(0.01),
        discountAmount: z.number().optional(),
        discountPercentage: z.number().optional(),
        note: z.string().optional(),
        enableSurcharge: z.boolean(),
      })
    ),
  });
  const { register, formState, watch, setValue, trigger, reset } = form;

  const watchAmount = watch("amount");
  const watchNote = watch("note");
  const watchEnableSurcharge = watch("enableSurcharge");

  const { data: patientOptions } = trpc.viewer.patients.getNameList.useQuery({
    patientName: debouncedSearchQuery,
    patientId: debouncedSearchQuery ? undefined : patient,
    officeId,
  });

  const { data: singlePatient } = trpc.viewer.patients.getById.useQuery(
    {
      billingOfficeId: officeId,
      patientId: Number(patient),
    },
    { enabled: !!patient }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(search);
    }, 800);
    return () => clearTimeout(timeoutId);
  }, [search]);

  useEffect(() => {
    setValue("amount", singlePatient?.balance || 0);
  }, [singlePatient]);

  useEffect(() => {
    if (cardList && cardList.length) {
      setPaymentOption("use-existing");
    } else {
      setPaymentOption("add-new");
    }
  }, [cardList?.length]);

  useEffect(() => {
    if (office && card) {
      setValue(
        "enableSurcharge",
        office.applySurchargeFee &&
          office.surchargeOnVirtualPay &&
          !isACHPayment &&
          card.cardType === "CREDIT"
      );
    }
  }, [office, isACHPayment, card]);

  useEffect(() => {
    if (office) {
      setValue("enableSurcharge", office.applySurchargeFee && office.surchargeOnVirtualPay);
    }
  }, [office]);

  useEffect(() => {
    if (office && !isACHPayment && watchEnableSurcharge) {
      setFee(
        calculateFee({
          amount: Math.round(watchAmount * 100),
          isACHPayment,
          applySurcharge: true,
        })
      );
    } else {
      setFee(0);
    }
  }, [office, isACHPayment, watchAmount, watchEnableSurcharge]);

  useEffect(() => {
    if (card) {
      setIsACHPayment(card.type === "BANK_ACCOUNT");
    }
  }, [card, paymentOption]);

  const saveVirtualPayment = trpc.viewer.patients.processVirtualPayment.useMutation({
    onSuccess: ({ applySurcharge }) => {
      setLoading(false);
      setSurchargeApplied(applySurcharge);
      showToast("Success", "success");
      setPaymentSuccessDialogOpen(true);
    },
    onError: (e) => {
      showToast(e.message || "Error", "error");
      setLoading(false);
    },
  });

  const handleSubmit = (data: FormValues) => {
    if (!patient) {
      showToast("Please select a patient", "error");
      return;
    }
    setLoading(true);
    saveVirtualPayment.mutate({
      patientId: patient,
      officeId,
      ...data,
      isNewMethod: false,
    });
  };

  const handleFinixPayment = async (token: string) => {
    if (!patient) {
      showToast("Please select a patient", "error");
      return;
    }

    const validAmount = await trigger("amount");
    if (!validAmount) return;
    setLoading(true);

    saveVirtualPayment.mutate({
      patientId: patient,
      officeId,
      amount: watchAmount,
      enableSurcharge: watchEnableSurcharge,
      note: watchNote,
      token,
      isNewMethod: true,
    });
  };

  return (
    <div className="rounded-md bg-white text-sm text-gray-500">
      <Form form={form} handleSubmit={handleSubmit}>
        <div className="mt-6 flex flex-col gap-5">
          <div className="">
            <Label>Account</Label>
            <div className="relative w-full">
              <Select
                placeholder="Search Accounts"
                className="relative rounded-xl"
                value={patient ? patientOptions?.find((p) => p.value === patient) : null}
                options={patientOptions}
                onInputChange={(e) => setSearch(e)}
                onChange={(option) => {
                  if (option) {
                    setPatient(option.value);
                  }
                }}
              />
            </div>
          </div>
          <div className="">
            <Label>Amount</Label>
            <TextField
              type="number"
              className="relative rounded-lg text-gray-900"
              label=""
              step={0.01}
              addOnLeading={<DollarIcon />}
              {...register("amount", { valueAsNumber: true })}
            />
          </div>
          {office?.applySurchargeFee && (
            <div className="md:col-span-2">
              <Controller
                name="enableSurcharge"
                render={({ field: { value, onChange } }) => (
                  <Switch
                    label="Enable surcharge: Customers pay 2.89% for online payments"
                    checked={value}
                    onCheckedChange={onChange}
                    disabled={isACHPayment || (card && card?.cardType !== "CREDIT")}
                  />
                )}
              />
            </div>
          )}
          <div className="md:col-span-1">
            <Label>Note</Label>
            <TextAreaField
              className="rounded-lg text-gray-900"
              placeholder="Note will be included with transaction details."
              rows={3}
              {...register("note")}
              label=""
            />
          </div>
          <div className="border-b border-gray-200" />
          <div className="space-y-2">
            <p className="text-base font-medium text-gray-900">Payment Method</p>
          </div>
          <RadioGroup.Root
            className="space-y-2"
            defaultValue={paymentOption}
            value={paymentOption}
            onValueChange={(value) => {
              setPaymentOption(value);
            }}>
            <div ref={animationParentRef} className="space-y-6">
              <div className="flex items-center">
                <RadioGroup.Item
                  id="add-new"
                  value="add-new"
                  className="min-w-4 flex h-4 w-4 cursor-pointer items-center rounded-full border border-blue-500 bg-white focus:border-2 focus:outline-none ltr:mr-2 rtl:ml-2">
                  <RadioGroup.Indicator className="relative flex h-4 w-4 items-center justify-center after:block after:h-2 after:w-2 after:rounded-full after:bg-blue-500" />
                </RadioGroup.Item>
                <Label htmlFor="add-new" className="!m-0 flex items-center">
                  Manually enter payment information
                </Label>
              </div>
              {paymentOption === "add-new" && (
                <div className="pl-6">
                  <PaymentForm
                    appId={appId}
                    env={env}
                    buttonBackColor="#111827"
                    buttonText={`Process $${
                      watchAmount > 0 ? formatNumber(watchAmount + fee / 100, "balance") : "0.00"
                    } Payment`}
                    handlePayment={handleFinixPayment}
                    isPaymentProcessing={loading}
                    enableACH={office?.enableFinixACHPayment}
                    setIsACHPayment={setIsACHPayment}
                  />
                </div>
              )}
              {patient && (
                <div className="flex items-center">
                  <RadioGroup.Item
                    id="use-existing"
                    value="use-existing"
                    className="min-w-4 flex h-4 w-4 cursor-pointer items-center rounded-full border border-blue-500 bg-white focus:border-2 focus:outline-none ltr:mr-2 rtl:ml-2">
                    <RadioGroup.Indicator className="relative flex h-4 w-4 items-center justify-center after:block after:h-2 after:w-2 after:rounded-full after:bg-blue-500" />
                  </RadioGroup.Item>
                  <Label htmlFor="use-existing" className="!m-0 flex items-center">
                    Use payment method on file
                  </Label>
                </div>
              )}
              {paymentOption === "use-existing" && (
                <div className="">
                  <div className="w-full pl-6">
                    <div className="flex w-full gap-2">
                      <div className="relative w-full">
                        <Select
                          className="rounded-xl"
                          options={cardOptions}
                          value={cardOptions?.find((c) => c.value === card?.id)}
                          onChange={(option) => {
                            if (option) {
                              setValue("cardId", option.value);
                              setCard(cardList?.find((card) => card.id === option.value));
                            }
                          }}
                          hasIcon={!!card}
                        />
                        {card && (
                          <img
                            src={
                              getCardBrand(
                                `${card?.brand ? card?.brand + ":" + card.lastFour : card?.lastFour}`
                              ).logo
                            }
                            alt="card brand image"
                            className="absolute top-2 left-1 h-5 w-5"
                          />
                        )}
                      </div>
                    </div>
                    {formState.errors.cardId && (
                      <div className="text-gray mt-2 flex items-center gap-x-2 text-sm text-red-700">
                        <div>
                          <svg
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="h-3 w-3"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="10" />
                            <line x1="12" y1="16" x2="12" y2="12" />
                            <line x1="12" y1="8" x2="12.01" y2="8" />
                          </svg>
                        </div>
                        <p>Required</p>
                      </div>
                    )}
                  </div>

                  <div className="pl-6">
                    <Button
                      className="hover:bg-balck mt-4 flex h-10 w-full justify-center bg-black text-white hover:text-white"
                      type="submit"
                      loadingTextClassName="text-black"
                      loading={loading}>
                      {`Process $${
                        watchAmount > 0 ? formatNumber(watchAmount + fee / 100, "balance") : "0.00"
                      } Payment`}
                    </Button>
                  </div>
                </div>
              )}
              <p className="pl-6 leading-6">
                If a debit card is used for this transaction, the patient will not incur a surcharge. This
                will be reflected in the payment success message once the payment is processed.
              </p>
            </div>
          </RadioGroup.Root>
        </div>
      </Form>

      {singlePatient && (
        <PaymentSuccessDialog
          title="Payment successful"
          description="Thanks for your payment, your payment was successful."
          amount={(surchargeApplied ? watchAmount + fee : watchAmount) / 100}
          account={singlePatient.firstName + " " + singlePatient.lastName}
          date={new Date().toLocaleDateString()}
          source="Virtual Payment"
          open={paymentSuccessDialogOpen}
          handler={() => {
            setPaymentSuccessDialogOpen(false);
            setPatient(undefined);
            reset();
          }}
          onClose={() => {
            setPaymentSuccessDialogOpen(false);
            setPaymentDialog(false);
            setPatient(undefined);
            reset();
          }}
          email={singlePatient.email || ""}
          phone={singlePatient.phoneNumbers[0]?.number || ""}
          applySurcharge={watchEnableSurcharge}
          surchargeApplied={surchargeApplied}
          // footer={
          //   <p className="text-center text-sm text-gray-500">
          //     We have sent detailed to the email {singlePatient.email || ""} and phone number of{" "}
          //     {singlePatient.phoneNumbers[0]?.number || ""}
          //   </p>
          // }
        />
      )}
    </div>
  );
}
